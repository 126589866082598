import React from 'react';
import './Button.scss';

/**
 * @namespace Button
 * @function Button
 * @author Ryan Groom
 * @since 07/11/22
 * @version 1.0.0
 * @component
 */
export default function Button({
  className = 'Button-Blue',
  buttonText,
  onClick,
  submit,
  external,
  disabled,
}) {
  const button = [];

  if (typeof onClick === 'string' && !submit) {
    button.push(
      <a
        href={onClick}
        target={external ? 'blank' : undefined}
        rel={external ? 'noreferrer' : undefined}
        className={`Button ${className}`}
      >
        {buttonText}
      </a>
    );
  } else if (typeof onClick === 'function' || submit) {
    button.push(
      <button
        className={`Button ${className}`}
        onClick={() => {
          onClick();
        }}
        disabled={disabled}
        type={submit ? 'submit' : null}
      >
        {buttonText}
      </button>
    );
  } else return null;

  return <>{button}</>;
}
