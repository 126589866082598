import * as React from 'react';
import Banner from '../../Components/Banner/Banner';
import Layout from '../../Components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '../../Components/Button/Button';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import './styles.scss';

const GolfersPage = () => {
  const data = useStaticQuery(graphql`
    query golfersQuery {
      allSanityGolfer {
        nodes {
          email
          name
          phone
          type
        }
      }
    }
  `);
  const all = data?.allSanityGolfer?.nodes;
  const allEmails = all.map((a) => a.email);
  const golfers = all.filter((a) => a.type === 'golfer');
  const golfersEmails = golfers.map((a) => a.email);
  const teams = all.filter((a) => a.type === 'team');
  const teamsEmails = teams.map((a) => a.email);
  const sponsors = all.filter((a) => a.type === 'sponsor');
  const sponsorsEmails = sponsors.map((a) => a.email);
  console.log(sponsors);
  return (
    <Layout className='Golfers'>
      <Banner title='Golfers' />
      <section className='Background-White Frame-Inner'>
        <Button
          onClick={`mailto:${allEmails.join(',')}`}
          buttonText='Email Everyone'
        />
        <Button
          onClick={`mailto:${golfersEmails.join(',')}`}
          buttonText='Email Individuals'
        />
        <Button
          onClick={`mailto:${teamsEmails.join(',')}`}
          buttonText='Email Teams'
        />
        <Button
          onClick={`mailto:${sponsorsEmails.join(',')}`}
          buttonText='Email Sponsors'
        />
      </section>
      <section className='Background-Blue'>
        <div className='Sign-Up-Lists Frame-Inner'>
          <div>
            <h2>Individuals</h2>
            <ul>
              {golfers.map((golfer, index) => {
                return (
                  <li key={golfer.name + index}>
                    {golfer.name}
                    {golfer.phone ? (
                      <a href={`tel:${golfer.phone}`}>
                        <FaPhone />
                      </a>
                    ) : null}
                    {golfer.email ? (
                      <a href={`mailto:${golfer.email}`}>
                        <FaEnvelope />
                      </a>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2>Teams</h2>
            <ul>
              {teams.map((golfer, index) => {
                return (
                  <li key={golfer.name + index}>
                    {golfer.name}
                    {golfer.phone ? (
                      <a href={`tel:${golfer.phone}`}>
                        <FaPhone />
                      </a>
                    ) : null}
                    {golfer.email ? (
                      <a href={`mailto:${golfer.email}`}>
                        <FaEnvelope />
                      </a>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2>Sponsors</h2>
            <ul>
              {sponsors.map((golfer, index) => {
                return (
                  <li key={golfer.name + index}>
                    {golfer.name}
                    {golfer.phone ? (
                      <a href={`tel:${golfer.phone}`}>
                        <FaPhone />
                      </a>
                    ) : null}
                    {golfer.email ? (
                      <a href={`mailto:${golfer.email}`}>
                        <FaEnvelope />
                      </a>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GolfersPage;
